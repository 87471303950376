import {useState} from 'react';
import RecipeSearchResult from "../../components/recipe-search-result/RecipeSearchResult";
import searchService from "../../services/SearchService";
import './Search.css';

const RECIPE_TYPES = ['ALL', 'DESSERT', 'BREAKFAST', 'LUNCH', 'DINNER', 'SIDE']

export default function Search() {
  const [recipeName, setRecipeName] = useState('');
  const [recipeType, setRecipeType] = useState(RECIPE_TYPES[0]);
  const [recipeMaxTime, setRecipeMaxTime] = useState(0);

  const [searchedRecipes, setSearchedRecipes] = useState([]);

  function handleRecipeNameChange(event) {
    setRecipeName(event.target.value);
  }

  function handleRecipeTypeChange(event) {
    setRecipeType(event.target.value);
  }

  function handleRecipeMaxTimeChange(event) {
    setRecipeMaxTime(event.target.value);
  }

  function handleSearchButtonClick(event) {
    event.preventDefault();
    const searchResults = searchService.search({
      "name": recipeName,
      "type": recipeType,
      "maxTime": recipeMaxTime
    });
    setSearchedRecipes(searchResults);
  }

  return (<div className="search-container">
    <form className="search-form">
      <div className="search-form-input-container">
        <label htmlFor="search-name-input">Recipe Name</label>
        <input id="search-name-input" type="text" value={recipeName} onChange={handleRecipeNameChange}></input>
      </div>
      <div className="search-form-input-container">
        <label htmlFor="search-type-select">Recipe Type</label>
        <select id="search-type-select" onChange={handleRecipeTypeChange} value={recipeType}>
          {
            RECIPE_TYPES.map((item,key) => (<option value={item} key={key}>{item}</option>))
          }
        </select>
      </div>
      <div className="search-form-input-container">
        <label htmlFor="search-time-input">Maximum Time (minutes)</label>
        <input id="search-time-input" type="number" value={recipeMaxTime} onChange={handleRecipeMaxTimeChange}></input>
      </div>
      <button onClick={handleSearchButtonClick}>Search</button>
    </form>
    <section className="search-results-container">
      {
        searchedRecipes.map((searchedRecipe, index) => {
          return (<RecipeSearchResult recipe={searchedRecipe} key={index} />);
        })
      }
    </section>
  </div>);
}