import './App.css';
import Header from './components/header/Header';
import {Routes, Route} from 'react-router';
import Search from "./pages/search/Search";
import RecipeDetails from "./pages/recipe-details/RecipeDetails";

function App() {

  return (
    <div className="App">
      <Header></Header>
      <main>
        <Routes>
          <Route index element={<Search/>}/>
          <Route path="recipe/:recipeId" element={<RecipeDetails/>}/>
        </Routes>
      </main>
    </div>
  );
}

export default App;
