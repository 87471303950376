import './RecipeSearchResult.css'
import { Link } from "react-router";

export default function RecipeSearchResult({recipe}) {
  return (
    <Link to={`/recipe/${recipe.id}`}>
      <article className="recipe-search-result">
        <h2 className="recipe-search-result-title">{recipe.name}</h2>
        <p className="recipe-search-result-time">Time: {recipe.time}</p>
      </article>
    </Link>
  )
}