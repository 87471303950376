import recipes from '../recipes.json';

class SearchService {
  search({name, type, maxTime}) {
    return recipes
      .filter(recipe => recipe.name.toLowerCase().includes(name.toLowerCase()))
      .filter(recipe => {
        return type === 'ALL' ? true : recipe.types.some(recipeType => recipeType === type)
      })
      .filter(recipe => {
        return recipe.time && maxTime ? recipe.time <= maxTime  : true
      });
  }
}

const searchService = new SearchService();
export default searchService;