import { useParams } from "react-router";
import {useState} from "react";
import recipes from "../../recipes.json";
import './RecipeDetails.css';

export default function RecipeDetails() {
  const params = useParams();
  const recipe = recipes.find(recipe => {
    return recipe.id === params.recipeId
  });
  const [selectedTab, setSelectedTab] = useState(0);

  function handleTabClick(tabId) {
    setSelectedTab(tabId);
  }

  return (
    <div className="recipe-details">
      <h1>
        {recipe.name}
      </h1>
      <button onClick={(e) => handleTabClick(0)}>Overview</button>
      <button onClick={(e) => handleTabClick(1)}>Ingredients</button>
      <button onClick={(e) => handleTabClick(2)}>Steps</button>
      {selectedTab === 0 && (
        <>
          <p>Time until ready: {recipe.time}</p>
          {recipe.originalRecipe && <p>Original recipe: {recipe.originalRecipe}</p>}
        </>
      )}
      {selectedTab === 1 && (
        <>
          {recipe.ingredients.map((ingredient, index) => {
            return (
              <li key={index}>
                {ingredient}
              </li>
            )
          })}
        </>
      )}
      {selectedTab === 2 && (
        <ol>
          {recipe.steps.map((step, index) => {
            return (<li key={index}>{step}</li>)
          })}
        </ol>
      )}
    </div>
  );
}